import CoursePicker from 'components/common/course-picker/CoursePicker';
import { CourseSection } from 'reports/cw/models';

interface EHRCoursePickerButtonProps {
  courseSectionInfo?: CourseSection;
}

const CWCoursePickerButton = ({ courseSectionInfo }: EHRCoursePickerButtonProps) => (
  <CoursePicker pendoClass="cw-take-tour-button" currentCourseName={courseSectionInfo?.courseName} />
);

export default CWCoursePickerButton;
