import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, appSelectors } from 'redux/ducks/app';

interface UseExpandRowReturnType {
  expandedRows: Array<number | string>;
  handleExpandRow: (selectedIndex: number | string) => void;
  isRowExpanded: (selectedIndex: number | string) => boolean;
}
interface UseExpandRowProps {
  trackAAFunction?: (selectedId: number | string) => void;
}

const useExpandRow = ({ trackAAFunction }: UseExpandRowProps = {}): UseExpandRowReturnType => {
  const dispatch = useDispatch();
  const toggleExpandRowId = useSelector(appSelectors.getToggleExpandRowId);
  const [expandedRows, setExpandedRows] = useState<Array<number | string>>([]);

  const handleExpandRow = (selectedId: number | string) => {
    dispatch(appActions.setSelectedExpandRowId(selectedId));
    if (trackAAFunction) {
      trackAAFunction(selectedId);
    }
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(selectedId);
    const newExpandedRows = isRowExpanded ? currentExpandedRows.filter(id => id !== selectedId) : currentExpandedRows.concat(selectedId);
    setExpandedRows(newExpandedRows);
  };

  const isRowExpanded = (selectedId: number | string): boolean => {
    return expandedRows.includes(selectedId);
  };

  useEffect(() => {
    if (toggleExpandRowId) {
      handleExpandRow(toggleExpandRowId);
    }
  }, [toggleExpandRowId]);

  return { expandedRows, handleExpandRow, isRowExpanded };
};

export default useExpandRow;
