import React, { useState, useEffect, FC, useCallback, ReactElement } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';

import { ELSFooter } from '@els/els-component-footer-react';
import { ELSInlineLoader } from '@els/els-ui-common-react';
import { appSelectors } from 'redux/ducks/app';
import { ELSTabGroup, ELSTab } from 'components/common';
import { Breadcrumb, BreadcrumbItem } from 'components/common/breadcrumb';
import CoursePicker from 'components/common/course-picker/CoursePicker';
import PageHeader from 'components/common/page-header/PageHeader';
import PageWrapper from 'components/common/page-wrapper/PageWrapper';
import withAppLinkingBackToPreviousApp from 'components/common/with-app-linking-back-to-previous-app/withAppLinkingBackToPreviousApp';
import { ANALYTIC_FEATURES, FeatureFlagNames, REPORT_TITLE, RoutePath } from 'constants/app.constant';
import { trackAAFeature } from 'services/adobe-analytics.service';
import { AssignmentTypeOptions, FeatureFlag, WindowMode } from 'models';
import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { ASSIGNMENT_TYPES } from 'reports/cw/constants/report.constant';
import { AssignmentEngagementDTO, AssignmentEngagementMetricDTO, CourseSection, InteractionSortingBetweenCardAndTable } from 'reports/cw/models';
import { courseSelectors } from 'reports/cw/redux/ducks/courses';
import { cwActions, cwSelectors } from 'reports/cw/redux/ducks/cw';
import { filterActions, filterSelectors } from 'redux/ducks/filter';
import { renderNoDataFound } from 'helpers/ui.helper';
import withResizeScreen from 'components/common/with-resize-screen/withResizeScreen';
import { renderCourseNameBreadcrumb } from 'services/app-linking.service';
import { isFeatureEnabled } from 'services/feature-flag.service';
import { APP_LINK_PAGE_NAME_WITH_ROUTE } from 'constants/externalApp.constant';
import AssignmentEngagement from '../assignment-engagement/AssignmentEngagement';
import StudentEngagement from '../student-engagement/StudentEngagement';
import EngagementHeader from './engagement-header/EngagementHeader';
import CWCoursePickerButton from '../../common/cw-course-picker-button/CWCoursePickerButton';

require('reports/cw/assets/images/eaq.svg');

export const ENGAGEMENT_TABS = {
  student: {
    name: 'Student Engagement',
    index: 0
  },
  assignment: {
    name: 'Assignment Engagement',
    index: 1
  }
} as const;

interface EngagementProps {
  assignmentTypeId: string;
  courseSectionInfo: CourseSection;
  courseId: number;
  isAssignmentEngagementReportLoading: boolean;
  isStudentEngagementReportLoading: boolean;
  isBackToPreviousAppIconShown: boolean;
  assignmentEngagementCardAndTableSortInteraction: InteractionSortingBetweenCardAndTable;
  studentEngagementCardAndTableSortInteraction: InteractionSortingBetweenCardAndTable;
  assignmentEngagement: AssignmentEngagementDTO;
  assignmentTypeOptions: AssignmentTypeOptions[];
  fetchAssignmentEngagementReport: Function;
  fetchStudentEngagementReport: Function;
  setAssignmentEngagementCardAndTableSortInteraction: Function;
  setStudentEngagementCardAndTableSortInteraction: Function;
  setAssignmentTypeId: Function;
  xLinkId: string;
  handleBackPreviousAppIconClick: Function;
  windowMode: WindowMode;
  featureFlags: FeatureFlag[];
}

export const getFilteredAssignmentEngagement = (assignmentEngagement: AssignmentEngagementMetricDTO[], assignmentType: string): AssignmentEngagementMetricDTO[] => {
  if (assignmentType === ASSIGNMENT_TYPES.LESSONS.id) {
    return assignmentEngagement.filter(ae => ae.assignmentType === ASSIGNMENT_TYPES.LESSONS.id || ae.assignmentType === ASSIGNMENT_TYPES.ADAPTIVE_LESSON.id);
  }
  return assignmentEngagement.filter(ae => ae.assignmentType === assignmentType);
};

export const getAssignmentTypeOptions = (listContentTypeAvailable: string[]): AssignmentTypeOptions[] => {
  let assignmentTypeOptions = [];
  Object.values(ASSIGNMENT_TYPES).forEach(assignmentType => {
    if (listContentTypeAvailable.includes(assignmentType.id)) {
      const { id, name, icon } = assignmentType;
      assignmentTypeOptions = uniqBy(
        [
          ...assignmentTypeOptions,
          {
            key: id,
            name,
            icon
          }
        ],
        'name'
      );
    }
  });
  return assignmentTypeOptions;
};

const Engagement: FC<EngagementProps> = ({
  assignmentTypeId,
  courseSectionInfo,
  isAssignmentEngagementReportLoading,
  isStudentEngagementReportLoading,
  courseId,
  assignmentEngagement,
  assignmentTypeOptions,
  fetchAssignmentEngagementReport,
  fetchStudentEngagementReport,
  setAssignmentTypeId,
  xLinkId,
  handleBackPreviousAppIconClick,
  isBackToPreviousAppIconShown,
  windowMode,
  featureFlags
}) => {
  const location = useLocation();
  const history = useHistory();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [filteredAssignmentEngagement, setFilteredAssignmentEngagement] = useState([]);
  const isIncorrectDataHidden = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_HIDE_INCORRECT_DATA, `${courseId}`);
  const isGradePointsEnabled = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_GRADE_WEIGHTING_SCORE_ENABLED);

  const changeTab = useCallback(
    (index: number) => {
      const {
        student: { index: studentIdx }
      } = ENGAGEMENT_TABS;
      setActiveTabIndex(index);
      if (index === studentIdx) {
        trackAAFeature(ANALYTIC_FEATURES.CW_ENGAGEMENT_STUDENT_TAB_VIEW);
        history.push(PerformancePath.engagement.student);
      } else {
        trackAAFeature(ANALYTIC_FEATURES.CW_ENGAGEMENT_ASSIGNMENT_TAB_VIEW);
        history.push(PerformancePath.engagement.assignment);
      }
    },
    [history]
  );

  const setInitialTab = useCallback(() => {
    if (location.pathname === PerformancePath.engagement.student) {
      setActiveTabIndex(ENGAGEMENT_TABS.student.index);
    } else {
      setActiveTabIndex(ENGAGEMENT_TABS.assignment.index);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (assignmentTypeId) {
      fetchStudentEngagementReport(courseId, assignmentTypeId);
    }
  }, [assignmentTypeId, courseId, fetchStudentEngagementReport]);

  // TODO: When new home page ticket is done
  //  ---> Move call API AssignmentEngagement into new Home Page to use this data in Home Page, Assignment Engagement, and Assignment Summary
  useEffect(() => {
    fetchAssignmentEngagementReport(courseId, assignmentTypeId);
  }, [assignmentTypeId, courseId, fetchAssignmentEngagementReport]);

  useEffect(() => {
    setInitialTab();
  }, [isAssignmentEngagementReportLoading, isStudentEngagementReportLoading, setInitialTab]);

  useEffect(() => {
    setFilteredAssignmentEngagement(getFilteredAssignmentEngagement(assignmentEngagement?.metrics || [], assignmentTypeId));
  }, [assignmentTypeId, assignmentEngagement]);

  const currentAppLinkPage =
    activeTabIndex === ENGAGEMENT_TABS.assignment.index ? APP_LINK_PAGE_NAME_WITH_ROUTE.CW_ASSIGNMENT_ENGAGEMENT.name : APP_LINK_PAGE_NAME_WITH_ROUTE.CW_STUDENT_ENGAGEMENT.name;

  const buildContent = (): ReactElement => {
    if (assignmentTypeOptions?.length && assignmentTypeId) {
      return (
        <div className="c-cw-engagement__content">
          <div className="u-els-background-color-n0 c-cw-engagement__content-header-wrapper">
            <div className="u-max-width-1400 u-els-padding-2x u-els-padding-bottom u-els-padding-left@mobile u-els-padding-right@mobile u-els-width-1o1 c-cw-report-content">
              <EngagementHeader
                filteredAssignmentEngagement={filteredAssignmentEngagement}
                assignmentTypeOptions={assignmentTypeOptions}
                assignmentTypeValue={assignmentTypeId}
                onSetAssignmentTypeValue={setAssignmentTypeId}
                trackAAFeature={trackAAFeature}
                onChangeTab={changeTab}
                isIncorrectDataHidden={isIncorrectDataHidden}
              />
            </div>
          </div>

          <div className="u-max-width-1400 u-els-padding-1x1o2 u-els-padding-none@mobile u-els-width-1o1 c-cw-report-content">
            <ELSTabGroup
              navWrapperCssClasses={['u-els-margin-left-1o2', 'u-els-margin-left@mobile', 'c-els-divider c-els-divider--1o2']}
              activeIndex={activeTabIndex}
              onChangeActiveIndex={changeTab}
              hasDivider={false}
            >
              <ELSTab title={ENGAGEMENT_TABS.student.name}>
                {isStudentEngagementReportLoading ? (
                  <ELSInlineLoader />
                ) : (
                  <StudentEngagement
                    assignmentTypeId={assignmentTypeId}
                    filteredAssignmentEngagement={filteredAssignmentEngagement}
                    isIncorrectDataHidden={isIncorrectDataHidden}
                    isGradePointsHidden={!isGradePointsEnabled}
                  />
                )}
              </ELSTab>
              <ELSTab title={ENGAGEMENT_TABS.assignment.name}>
                <AssignmentEngagement
                  assignmentTypeId={assignmentTypeId}
                  filteredAssignmentEngagement={filteredAssignmentEngagement}
                  isIncorrectDataHidden={isIncorrectDataHidden}
                />
              </ELSTab>
            </ELSTabGroup>
            <div className="c-els-divider c-els-divider--1o2" />
          </div>
        </div>
      );
    }
    return renderNoDataFound();
  };
  return (
    <PageWrapper>
      <PageHeader
        showNavigation
        title={REPORT_TITLE.SHERPATH_PERFORMANCE_GRADES.title}
        componentBelowHeader={<CWCoursePickerButton courseSectionInfo={courseSectionInfo} />}
        currentAppLinkPage={currentAppLinkPage}
      >
        <Breadcrumb>
          {courseSectionInfo ? renderCourseNameBreadcrumb(courseSectionInfo.courseName, xLinkId, isBackToPreviousAppIconShown, handleBackPreviousAppIconClick) : ''}
          {!windowMode.mobile && (
            <BreadcrumbItem>
              <Link to={RoutePath.cwIns}>Performance</Link>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <Link to={PerformancePath.engagementSummary}>Engagement</Link>
          </BreadcrumbItem>
          {assignmentTypeId && !windowMode.mobile && <BreadcrumbItem>{ASSIGNMENT_TYPES[assignmentTypeId]?.name}</BreadcrumbItem>}
        </Breadcrumb>
      </PageHeader>
      <div className="c-cw-engagement">
        {isAssignmentEngagementReportLoading ? (
          <div className="c-cw-engagement__loader">
            <ELSInlineLoader />
          </div>
        ) : (
          buildContent()
        )}
      </div>
      <ELSFooter />
    </PageWrapper>
  );
};

const mapStateToProps = state => ({
  courseSectionInfo: courseSelectors.getCourseSectionInfo(state),
  courseId: appSelectors.getSelectedCourse(state),
  assignmentEngagement: cwSelectors.getAssignmentEngagement(state),
  assignmentTypeOptions: cwSelectors.getAssignmentTypeOptions(state),
  isStudentEngagementReportLoading: cwSelectors.getStudentEngagementReportLoading(state),
  isAssignmentEngagementReportLoading: cwSelectors.getAssignmentEngagementReportLoading(state),
  reportLoading: cwSelectors.getReportLoading(state),
  assignmentTypeId: filterSelectors.getCWEngagementAssignmentTypeDropdownId(state),
  redirectUrl: appSelectors.getRedirectUrl(state),
  featureFlags: appSelectors.getFeatureFlags(state)
});

const mapDispatchToProps = dispatch => ({
  fetchAssignmentEngagementReport: (courseId: number, assignmentType: string) => dispatch(cwActions.fetchAssignmentEngagementReport(courseId, assignmentType)),
  fetchStudentEngagementReport: (courseId: number, assignmentType: string) => dispatch(cwActions.fetchStudentEngagementReport(courseId, assignmentType)),
  setAssignmentTypeId: (id: string) => dispatch(filterActions.setCWEngagementAssignmentTypeDropdownId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAppLinkingBackToPreviousApp(withResizeScreen(Engagement)));
